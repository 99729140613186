import React from 'react';
import { graphql } from 'gatsby';
// import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { DateTime } from 'luxon';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/hero';
import {options } from '../config/contentful';

export default function PostTemplate({
  data: {
    post: { title, date, body, excerpt, hero },
  }, // this prop will be injected by the GraphQL query below.
}) {
  return (
    <Layout container={false}>
      <SEO title={title} description={excerpt} image={hero && hero.fixed.src} />
      <Hero
        title={title}
        subtitle={DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL)}
        color="light"
        centered
        container
      />
      <main className="section">
        <div className="container is-narrow">
          <div className="content">{body && documentToReactComponents(body.json, options)}</div>
        </div>
      </main>
    </Layout>
  );
}
export const pageQuery = graphql`
  query($slug: String!) {
    post: contentfulPost(slug: { eq: $slug }) {
      title
      date
      excerpt
      hero {
        fixed(width: 1200, height: 600) {
          src
        }
      }
      body {
        json
      }
    }
  }
`;
